<template>
    <div class="inner-container explanatory-note">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div v-if="!bpMode" class="section-title budget"><span>Бюджетное планирование</span></div>
        <div v-if="!bpMode" class="section-subtitle budget">
            <i class="icon icon-grid"></i>
            <span>Бюджетные заявки<c-budg-form-lst :curFormSelect="curFormSelect"/></span>
            <div class="actions-tab in-tab" id="bp-info-tab" style="top: 100px">
                <!-- Инструкцию модуля -->
                <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none"
                            no-caret>
                    <template #button-content id="info-dropdown">
                        <div class="btn-download">
                            <div>
                                <span class="left">
                                    <i class="icon icon-info"></i>
                                </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-text>
                        <div class="info-button">
                            <span style="text-transform: none">Инструкция<i
                                class="icon icon-folder"></i></span>
                        </div>
                        <div class="info-text">
                            <p><span class="red bold" style="text-transform: none">Режим "Пояснительная записка"</span>
                            </p>
                            <p><span style="text-transform: none">Данный раздел предназначен для ввода данных пояснительной записки и
                                представления краткого описания ключевых аспектов деятельности ГУ за определенный период времени.
                                Пояснительная записка включает в себя информацию о достигнутых результатах,
                                текущей ситуации, проблемах и предложениях по их решению.</span></p>
                        </div>
                    </b-dropdown-text>
                </b-dropdown>
                <!-- Скачивание отчетов -->
                <b-dropdown v-if="!moduleTabSwitch && !bpMode" variant="link" right
                            toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item @click="firstReport2()">Отчет</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div v-if="moduleTabSwitch" class="filter-area">
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span
                            class="rc"><i class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!--------------- Плановый период ------------------->
                            <div class="filter-block">
                                <b-form-group label="Плановый период">
                                    <multiselect
                                        :disabled="mainTableOverlay"
                                        v-model="curYear"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать год"
                                        :options="yearPeriods"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgYearPeriod"
                                        ref="yearPeriodRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Фильтр по году-------------------->
                            <div class="filter-block">
                                <b-form-group :label="yearItem" class="small">
                                    <multiselect
                                        v-model="yearInPeriod"
                                        :options="yearsArr"
                                        placeholder="Год"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgYear"
                                        ref="curYearRef"
                                    />
                                </b-form-group>
                            </div>
                            <!----------------Администратор программ abp------------------->
                            <div class="filter-block">
                                <b-form-group label="Адм. программ">
                                    <multiselect
                                        v-model="curAbp"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать адм. программ"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgAbp"
                                        ref="abpRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Гос.учреждение------------------->
                            <div class="filter-block">
                                <b-form-group label="Гос. учреждение">
                                    <multiselect
                                        v-model="curGu"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать гос. учреждение"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgInstitution"
                                        ref="guRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Регион------------------->
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <b-form-input type="text" v-model="curRegionVal"
                                                  readonly></b-form-input>
                                </b-form-group>
                            </div>
                            <!----------------Вид данных------------------->
                            <div class="filter-block">
                                <b-form-group label="Вид данных">
                                    <multiselect
                                        v-model="curDataType"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать вид данных"
                                        :options="dataTypeList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgDataType"
                                        ref="dataTypeRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Версия бюджета------------------->
                            <div class="filter-block">
                                <b-form-group label="Версия бюджета">
                                    <multiselect
                                        v-model="curVersion"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать версию бюджета"
                                        :options="versionDataTypeList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgVersion"
                                        ref="versionRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="success" @click="chgParams"
                                          :disabled="isDisabled">Открыть
                                </b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            <c-breadcrumbs :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp"
                           :curGu="curGu" :curDataType="curDataType" :curVersion="curVersion"
                           @openFilterByRef="openFilterByRef"></c-breadcrumbs>
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="toggle-show">
                                <button type="button" class="btn btn-secondary"
                                        @click="collapseAll = !collapseAll;">
                                    <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </th>
                            <th style="width: 0px;">ФУНКЦИОНАЛЬНАЯ ГРУППА</th>
                            <th>АБП</th>
                            <th>ГУ</th>
                            <th>ПРОГРАММА</th>
                            <th>НАИМЕНОВАНИЕ</th>
                            <th>СТАТУС БЗ</th>
                            <th>СТАТУС ПОСЛЕДНЕГО РЕДАКТИРОВАНИЯ ПЗ</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(funcItem, funcItemIndx) of funcGrArr">
                            <tr :key="'funcItemIndx'+funcItemIndx" class="table-info">
                                <td class="toggle-show">
                                    <button type="button" class="btn btn-secondary"
                                            @click="collapseItems(funcItemIndx)">
                                        <i class="icon icon-chevron-circle"
                                           v-if="collapseArr[funcItemIndx]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180"
                                           v-else></i>
                                    </button>
                                </td>
                                <td colspan="8">{{ funcItem.name }}</td>
                            </tr>
                            <template v-if="!collapseArr[funcItemIndx]">
                                <template v-for="(abpItem, abpItemIndex) of abpListArr">
                                    <template v-if="abpItem!==null && abpItem.gr===funcItem.gr">
                                        <tr :key="`abpItemIndex${abpItemIndex}_${funcItemIndx}`"
                                            class="table-info" style="background-color: #ecf7ff">
                                            <td></td>
                                            <td class="toggle-show">
                                                <button type="button" class="btn btn-secondary"
                                                        @click="collapseItemsSec(abpItemIndex)">
                                                    <i class="icon icon-chevron-circle"
                                                       v-if="collapseArrSec[abpItemIndex]"></i>
                                                    <i class="icon icon-chevron-circle icon-rotate-180"
                                                       v-else></i>
                                                </button>
                                            </td>
                                            <td colspan="7">
                                                <template
                                                    v-if="abpItem!==null && abpItem.gr===funcItem.gr">
                                                    {{ abpItem.name }}
                                                </template>
                                            </td>
                                        </tr>
                                        <template v-if="!collapseArrSec[abpItemIndex]">
                                            <template
                                                v-for="(guItem, guItemIndex) of dictGuListArr">
                                                <template
                                                    v-if="guItem!==null && parseInt(guItem.abp_owner)===parseInt(abpItem.abp)">
                                                    <tr :key="`guItemIndex_${guItemIndex}_${abpItemIndex}_${funcItemIndx}`"
                                                        style="background-color: rgb(236, 247, 255);">
                                                        <td colspan="2"></td>
                                                        <td class="toggle-show">
                                                            <button type="button"
                                                                    class="btn btn-secondary"
                                                                    @click="collapseItemsThird(guItemIndex)">
                                                                <i class="icon icon-chevron-circle"
                                                                   v-if="collapseArrThird[guItemIndex]"></i>
                                                                <i class="icon icon-chevron-circle icon-rotate-180"
                                                                   v-else></i>
                                                            </button>
                                                        </td>
                                                        <td colspan="6">{{ guItem.name }}</td>
                                                    </tr>
                                                    <template v-if="!collapseArrThird[guItemIndex]">
                                                        <template
                                                            v-for="(elementItem, elementItemIndex) of programListArr">
                                                            <template
                                                                v-if="elementItem!==null && parseInt(elementItem.abp)===parseInt(guItem.abp_owner) && elementItem.gr===abpItem.gr && elementItem.abp === abpItem.abp && elementItem.gr === funcItem.gr">
                                                                <tr :key="`targetItemIndx_${guItemIndex}_${abpItemIndex}_${funcItemIndx}_${elementItemIndex}`">
                                                                    <td colspan="4"></td>
                                                                    <td>{{ elementItem.prg }}</td>
                                                                    <td>{{
                                                                            elementItem.name_ru
                                                                        }}
                                                                    </td>
                                                                    <td>{{
                                                                            elementItem.agrNameRu
                                                                        }}
                                                                    </td>
                                                                    <td>{{
                                                                            elementItem.updateDate
                                                                        }}
                                                                    </td>
                                                                    <td style="text-align: center">
                                                                        <b-dropdown
                                                                            id="dropdown-dropleft"
                                                                            dropleft class="more">
                                                                            <template
                                                                                v-slot:button-content>
                                                                                <i class="icon icon-more"></i>
                                                                            </template>
                                                                            <template>
                                                                                <template
                                                                                    v-if="relevance===true && elementItem.editStatus===true && (elementItem.editable===true || elementItem.editable===null || elementItem.editable==='') && (elementItem.status!=='' && elementItem.status!==null && elementItem.status!=='null')">
                                                                                    <b-dropdown-item
                                                                                        @click="editBtn(elementItem, true)">
                                                                                        Редактировать
                                                                                    </b-dropdown-item>
                                                                                    <b-dropdown-item
                                                                                        @click="deleteClk(elementItem)">
                                                                                        Удалить
                                                                                    </b-dropdown-item>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <b-dropdown-item
                                                                                        @click="editBtn(elementItem, false)">
                                                                                        Просмотр
                                                                                    </b-dropdown-item>
                                                                                </template>
                                                                            </template>
                                                                        </b-dropdown>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <c-explanatoryNoteTabs :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp"
                               :curGu="curGu" :curRegion="curRegion" :curDataType="curDataType"
                               :curVersion="curVersion" :openAddTab="openAddTab"
                               :moduleTabSwitch="moduleTabSwitch" :prgItems="prgItems"
                               :editable="editable" :userId="userId"
                               @moduleTabSwitches="moduleTabSwitches" v-if="openAddTab"/>
    </div>

</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Multiselect from 'vue-multiselect';
import CBudgetFormsList from "@/modules/budget-request/components/budget-forms-list";
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";
import CBreadCrumbs from './components/table-dri-breadcrumbs.vue';
import ExplanatoryNoteTabs from './components/ExplanatoryNoteTabs'
import {parseInt} from "lodash";
import VueElementLoading from 'vue-element-loading';
import {Ax} from "@/utils";
import {variantNameLangSupport} from "@/modules/budget/budgetCorrectHelper";
import axios from "axios";
import {makeToast} from "@/modules/budget/bip/bip-types";

export default {
    name: "initialFormOfNote",
    components: {
        'c-budg-form-lst': CBudgetFormsList,
        'c-breadcrumbs': CBreadCrumbs,
        'c-explanatoryNoteTabs': ExplanatoryNoteTabs,
        'multiselect': Multiselect,
        'loading': VueElementLoading
    },
    created() {
        this.userId = this.$store.getters.user_uuid;
        // console.log('this.userId: ' + this.userId);
        this.$watch('collapseAll', this.collapseAllFunc);
    },

    async mounted() {
        this.yearPeriods = this.years();
    },
    data() {
        return {
            bpMode: false,
            loading: false,
            moduleTabSwitch: true,
            isDisabled: true,
            openAddTab: false,
            curFormSelect: '/exp-note',
            yearItem: 'Год',
            addRecords: {
                name_ru: 'Добавить',
                name_en: 'Add',
                name_kk: 'Добавить'
            },
            curYear: {},
            chosenYear: 0,
            yearInPeriod: null,
            curFunGr: null,
            curAbp: null,
            curGu: null,
            curRegion: null,
            curRegionVal: null,
            curDataType: null,
            curVersion: null,
            actualDataType: null,
            actualVersion: null,
            mainTableOverlay: false,
            userId: null,
            edit: false,
            collapseAll: true,
            relevance: false,
            editable: false,
            prgItems: {},
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            funcGrBase: [],
            yearPeriods: [],
            yearsArr: [],
            funcGr: [],
            abpBase: [],
            abpBaseArr: [],
            fullGuBaseList: [],
            fullFilterArr: [],
            guBase: [],
            regionsList: [],
            dataTypeList: [],
            fullDataTypeList: [],
            versionList: [],
            versionDataTypeList: [],
            funcGrArr: [],
            abpListArr: [],
            formTotalArr: [],
            dictGuListArr: [],
            programListArr: [],
            formTotalAgreementArr: [],
            guListPre: [],
            regionListPre: [],
            dataTypeListPre: [],
            variantListPre: [],
            budgetRequestFormTotalArr: []
        }
    },

    methods: {

        async firstReport2() {
            const url = `${this.curAbp.abp}/${this.prgItems.prg}/${this.curGu.code}/${this.curDataType.code}/${this.yearInPeriod}/${this.curYear.year}/${this.curRegion}/${this.curVersion.variant_uuid}`;
            try {
                // list1 - START
                const list1 = []
                let responseInfo = await fetch(`/api/explanatory-note/budget-request-note/get/${url}`);
                responseInfo = await responseInfo.json();
                responseInfo.forEach((d) => {
                    list1.push({
                        id: d.id,
                        abp: d.abp,
                        prg: d.prg,
                        ppr: d.ppr,
                        gu: d.gu,
                        dataTypeId: d.dataTypeId,
                        year: d.year,
                        curYear: d.curYear,
                        region: d.region,
                        variant: d.variant,
                        nameKk: d.nameKk,
                        nameRu: d.nameRu,
                        titleIndicator: d.titleIndicator,
                        titleSorting: d.titleSorting,
                        userId: d.userId,
                        guNameKk: this.curGu.name_kk,
                        guNameRu: this.curGu.name_ru,
                        programKk: this.curDataType.name_kk,
                        programRu: this.curDataType.name_ru,
                        programNameKk: this.curVersion.name_kk,
                        programNameRu: this.curVersion.name_ru,
                        yearPeriod: this.curYear.name,
                        prgNameRu: this.prgItems.name_ru,
                        prgNameKk: this.prgItems.name_kk,

                    });
                });
                // list1 - END

                // list2 - START
                const list2 = [];
                let responseIndicator = await fetch(`/api/explanatory-note/note-target-indicator/get/${url}`);
                responseIndicator = await responseIndicator.json();

                let responseGoal = await fetch(`/api/explanatory-note/budget-request-note/forecast/${url}`);
                responseGoal = await responseGoal.json();

                responseGoal.programList.forEach((d) => {
                    const goalItem = responseGoal.goalList.find(it =>
                        it.programId === d.id);
                    const indicatorItem = responseGoal.indicatorList.find(it =>
                        it.goalId === goalItem.id &&
                        it.programId === d.id);
                    const note = responseIndicator.find(it =>
                        it.programId === d.id &&
                        it.goalId === goalItem.id &&
                        it.indicatorId === indicatorItem.id &&
                        it.forecastId === d.forecastId)
                    list2.push({
                        programId: d.id,
                        programCode: d.code,
                        programEn: d.name_en,
                        programKk: d.name_kz,
                        programRu: d.name_ru,
                        goalId: goalItem.id,
                        goalCode: goalItem.code,
                        goalEn: goalItem.name_en,
                        goalKk: goalItem.name_kz,
                        goalRu: goalItem.name_ru,
                        indicatorId: indicatorItem.id,
                        indicatorCode: indicatorItem.code,
                        indicatorEn: indicatorItem.name_en,
                        indicatorKk: indicatorItem.name_kz,
                        indicatorRu: indicatorItem.name_ru,
                        noteKk: note ? note.nameKk : "",
                        noteRu: note ? note.nameRu : "",
                        forecastId: d.forecastId,
                    });
                })
                // list2 - END

                // list3 - START
                const list3 = [];
                let responseExpenses = await fetch(`/api/explanatory-note/budget-request-form-total/get_ppr/${this.curAbp.abp}/${this.prgItems.prg}/${this.curGu.code}/${this.yearInPeriod}/${this.curVersion.variant_uuid}`);
                responseExpenses = await responseExpenses.json();

                responseExpenses.forEach((re) => {
                    const foundItem = responseInfo.find(it =>
                        it.abp === re.abp &&
                        it.ppr === re.ppr &&
                        it.prg === re.prg &&
                        it.titleIndicator.trim() === "ppr"
                    );
                    list3.push({
                        abp: re.abp,
                        gu: re.gu,
                        nameKk: re.name_kk,
                        nameRu: re.name_ru,
                        ppr: re.ppr,
                        prg: re.prg,
                        value: re.value,
                        noteRu: foundItem ? foundItem.nameRu : "",
                        noteKk: foundItem ? foundItem.nameKk : "",
                        prgNameRu: this.prgItems.name_ru,
                        prgNameKk: this.prgItems.name_kk,

                    });
                });
                // list3 - END

                const params = {
                    data1: list1,
                    data2: list2,
                    data3: list3
                };

                if (params) {
                    const axiosResponse = await axios.post('/api/word/write', params, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const url = window.URL.createObjectURL(new Blob([axiosResponse.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download',
                        `Пояснительная записка_${new Date().getFullYear()}.docx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                }
            } catch (error) {
                console.error(error);
                makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
            }
        },

        years() {
            const year = CUR_YEAR + 1;
            const periodLst = [];
            const startDate = 2021;
            const endDate = year + 2;
            let chosenYear = 0;
            for (let i = endDate; i >= startDate; i--) {
                periodLst.push({name: `${i} - ${i + 2}`, year: i});
                if (year === i) {
                    this.curYear = {name: `${i} - ${i + 2}`, year: i};
                    chosenYear = this.curYear.year;
                }
            }
            this.listOfYears(chosenYear);
            return periodLst;
        },
        chgYearPeriod() {
            this.loading = true;
            this.listOfYears(this.curYear.year);
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curAbp = null;
            this.curGu = null;
            this.curRegionVal = null;
            this.versionList = [];
            this.versionDataTypeList = [];
        },
        chgYear() {
            // this.loadGu(this.yearInPeriod);
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curAbp = null;
            this.curGu = null;
            this.curRegionVal = null;
            this.getFilterData(this.curYear.year, this.yearInPeriod, this.userId);
        },
        async getFilterData(curYear, year, userId) {
            this.loading = true;
            try {
                const result = await fetch(`/api/explanatory-note/budget-request-form-total/form-total-filter/${curYear}/${year}/${userId}`)
                    .then(response => response.json());
                if (result) {
                    // console.log('resultFilter: ' + JSON.stringify(result));
                    this.generateFilterList(result);
                    this.chgParams();
                    this.loading = false;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
            }
        },
        generateFilterList(mainArr) {
            this.guListPre = mainArr.guList;
            this.regionListPre = mainArr.regions;
            this.dataTypeListPre = mainArr.dataType;
            this.variantListPre = mainArr.variants;
            this.generateAbpList(mainArr.abpList);
        },

        generateAbpList(abpListArr) {
            this.abpBase = [];
            if (abpListArr.length > 0) {
                for (const el of abpListArr) {
                    this.abpBase.push(this.setNameLang(el, 'abp'));
                }
                if (this.abpBase.length > 0) {
                    this.curAbp = null;
                    this.curAbp = this.abpBase[0];
                    this.generateGuList(this.guListPre);
                    this.generateDataTypeList(this.dataTypeListPre);
                    this.generateVariantList(this.variantListPre);
                    this.chgAbp();
                }
            }
        },

        generateGuList(guListArr) {
            this.fullGuBaseList = [];
            if (guListArr.length > 0) {
                for (const el of guListArr) {
                    this.fullGuBaseList.push(this.setNameLang(el, 'code'));
                }
            }
        },

        generateDataTypeList(dataTypeListArr) {
            this.dataTypeList = [];
            this.fullDataTypeList = [];
            if (dataTypeListArr.length > 0) {
                for (const el of dataTypeListArr) {
                    this.dataTypeList.push(this.setNameLang(el, 'code'));
                    this.fullDataTypeList.push(this.setNameLang(el, 'code'));
                }
            }
        },

        generateVariantList(variantArr) {
            this.versionList = [];

            if (variantArr.length > 0) {
                for (const el of variantArr) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_ru = variantNameLangSupport(el, 'ru');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_kk = variantNameLangSupport(el, 'kk');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_en = variantNameLangSupport(el, 'en');
                    this.versionList.push(this.setNameLang(el, 'version'));
                }
            }
        },

        // -----Функция для очистки массива от дубликатов
        getUniqObjects(arr, param) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                let x = null;
                if (param === 'region') {
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region);
                } else if (param === 'dataType') {
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region && item.dataType === current.dataType);
                } else if (param === 'abp') {
                    x = enteredArr.find(item => item.abp === current.abp);
                } else if (param === 'gu') {
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu);
                } else if (param === 'variant') {
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region && item.dataType === current.dataType && item.variant === current.variant);
                } else if (param === 'abpWithoutPgr') {
                    x = enteredArr.find(item => item.abp === current.abp && item.gr === current.gr);
                }
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
            return filteredObjectArr;
        },
        /**
         * Событие для выборки справочника "Abp"
         */
        chgAbp() {
            this.guBase = [];
            const curAbp = this.curAbp.abp;
            if (curAbp !== '') {
                for (const guItem of this.fullGuBaseList) {
                    if (parseInt(guItem.abp) == parseInt(curAbp)) {
                        this.guBase.push(guItem)
                    }
                }
            }
            if (this.guBase.length > 0) {
                this.curGu = this.guBase[0];
                if (this.versionList.length > 0) {
                    this.getActualVersion(this.versionList);
                }
                this.chgInstitution();
            }
        },
        /**
         * Событие для выборки справочника "Gu"
         */
        chgInstitution() {
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curRegionVal = null;
            this.actualDataType = null;
            this.dataTypeList = this.fullDataTypeList;
            if (this.versionList.length > 0) {
                this.getActualVersion(this.versionList);
            }
            this.actualDataType = this.getActualDataType(this.dataTypeList, this.actualVersion);
            const region = this.curGu !== '' ? this.curGu.id_region : '';
            if (this.regionListPre.length > 0) {
                for (const reg of this.regionListPre) {
                    if (parseInt(reg.code) === parseInt(region)) {
                        this.curRegion = reg.code;
                        this.curRegionVal = reg.code + ' - ' + reg.name_ru;
                    }
                }
                if (this.dataTypeList.length > 0) {
                    if (Object.keys(this.actualVersion).length === 0) {
                        this.curDataType = this.dataTypeList[0];
                    } else {
                        this.curDataType = this.actualDataType;
                    }
                }
                this.chgDataType();
            }
        },
        /**
         * Событие для выборки справочника "Вид данных"
         */
        chgDataType() {
            this.curVersion = null;
            this.versionDataTypeList = [];
            if (this.versionList.length > 0) {
                const versionListArr = this.versionList;
                // console.log('this.curAbp.abp: ' + this.curAbp.abp);
                // console.log('this.curRegion: ' + JSON.stringify(this.curRegion));
                // console.log('this.curGu.code: ' + JSON.stringify(this.curGu.code));
                // console.log('this.curDataType.code: ' + JSON.stringify(this.curDataType.code));

                for (const version of versionListArr) {
                    if (this.curRegion === version.region && parseInt(this.curAbp.abp) === parseInt(version.abp) && this.curGu.code === version.gu && parseInt(this.curDataType.code) === parseInt(version.dataType)) {
                        this.versionDataTypeList.push(version);
                    }
                }
                if (this.versionDataTypeList.length > 0) {
                    this.curVersion = this.versionDataTypeList[0];
                    this.isDisabled = false;
                }

                this.getActualVersion(this.versionList);
            }
        },
        /*
            Извлекаю вид данных для актуальной записи, чтобы при загрузке страницы был выбран правильный "Вид данных"
         */
        getActualDataType(mainArr, obj) {
            let actualDataType = {};
            if (mainArr.length > 0 && Object.keys(obj).length !== 0) {
                for (const data of mainArr) {
                    if (parseInt(obj.dataType) === parseInt(data.code)) {
                        actualDataType = data;
                    }
                }
            }
            return actualDataType;
        },
        /*
            Извлекаю только актуальные данные attribute=true
         */
        getActualVersion(mainArr) {
            // console.log('abp: ' + abpItem + ' mainArr: ' + JSON.stringify(mainArr));
            this.actualVersion = null;
            let actualVersion = {};
            if (mainArr.length > 0) {
                for (const item of mainArr) {
                    if (item.attribute === true && parseInt(item.abp) === parseInt(this.curAbp.abp) && this.curGu.code === item.gu) {
                        actualVersion = item;
                    }
                }
            }
            this.actualVersion = actualVersion;
        },
        chgVersion() {
            if (Object.keys(this.curYear).length !== 0 && this.yearInPeriod != null && Object.keys(this.curAbp).length !== 0 && Object.keys(this.curGu).length !== 0 && this.curRegion != null && Object.keys(this.curDataType).length !== 0 && Object.keys(this.curVersion).length !== 0) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        chgParams() {
            this.loadBudgetRequestFormTotal();
            this.collapseAll = true;
            if (this.collapseArr.length > 0) {
                for (let i = 0; i < this.collapseArr; i++) {
                    this.collapseArr[i] = this.collapseAll;
                }
            }
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        collapseAllFunc() {
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrThird.length; i++) {
                this.collapseArrThird[i] = this.collapseAll;
            }
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        collapseItems(functItemIndx) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndx;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
        },
        collapseItemsSec(functItemIndx) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndx;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItemsThird(guItemIndx) {  // ---------Раскрытие четвертого уровня при нажатии на стрелку вниз
            const rowIndex = guItemIndx;
            this.collapseArrThird[rowIndex] = !this.collapseArrThird[rowIndex];
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        //-----Список годов в фильтрации по годам----//
        listOfYears(currentYear) {
            this.yearInPeriod = currentYear;
            this.yearsArr = [];
            if (currentYear !== null) {
                for (let i = currentYear; i <= (currentYear + 2); i++) {
                    this.yearsArr.push(i);
                }
            }
            this.getFilterData(this.curYear.year, this.yearInPeriod, this.userId);
        },

        async loadBudgetRequestFormTotal() {
            this.loading = true;
            let funcGrArr = [];
            let abpListArr = [];
            let dictGuListArr = [];
            let programListArr = [];
            let formTotalAgreementArr = [];
            this.funcGrArr = [];
            this.abpListArr = [];
            this.dictGuListArr = [];
            this.formTotalArr = [];
            this.programListArr = [];
            this.formTotalAgreementArr = [];
            if (this.curVersion !== null) {
                this.relevance = this.curVersion.relevance;
                // console.log('relevance: ' + this.relevance);
            }
            try {
                // console.log('this.curVersion: ' + JSON.stringify(this.curVersion));
                // console.log('year: ' + this.curYear.year);
                // console.log('curRegion: ' + this.curRegion);
                // console.log('abp: ' + this.curAbp.abp);
                // console.log('gu: ' + this.curGu.code);
                // console.log('dataType: ' + this.curDataType.code);
                if (this.curYear.year !== '' && this.curRegion !== '' && this.curAbp.abp !== '' && this.curGu.code !== '' && (this.curVersion !== null && this.curVersion.variant_uuid !== '')) {
                    // console.log('variant: ' + this.curVersion.variant_uuid);
                    const result = await fetch('/api/explanatory-note/budget-request-form-total/form-total/' + this.curYear.year + '/' + this.curRegion + '/' + this.curAbp.abp + '/' + this.curGu.code + '/' + this.curVersion.variant_uuid + '/' + this.curDataType.code)
                        .then(response => response.json());
                    if (result) {
                        // console.log('result: ' + JSON.stringify(result));
                        funcGrArr = result.funcGr;
                        abpListArr = result.abpList;
                        dictGuListArr = result.dictGuList;
                        programListArr = result.programList.dictEbkFunc;
                        formTotalAgreementArr = result.programList.formTotalAgreement;
                        if (funcGrArr.length > 0) {
                            for (const el of funcGrArr) {
                                this.funcGrArr.push(this.setNameLang(el, 'gr'));
                            }
                        }
                        if (abpListArr.length > 0) {
                            for (const el of abpListArr) {
                                this.abpListArr.push(this.setNameLang(el, 'abp'));
                            }
                            this.abpListArr = this.getUniqObjects(this.abpListArr, 'abpWithoutPgr');
                        }
                        if (dictGuListArr.length > 0) {
                            for (const el of dictGuListArr) {
                                this.dictGuListArr.push(this.setNameLang(el, 'code'));
                            }
                        }
                        if (programListArr.length > 0 && formTotalAgreementArr.length > 0) {
                            for (const el of programListArr) {
                                el.status = '';
                                el.agrNameRu = '';
                                el.agrNameKk = '';
                                el.agrNameEn = '';
                                el.editable = '';
                                let fullDate = '';
                                /**
                                 *  Извлекаю последнюю дату внесения изменения(если оно было сделано)
                                 */
                                if (el.dateOfRequestNoteUpdate) {
                                    const dateTimeString = new Date(el.dateOfRequestNoteUpdate).toLocaleString();
                                    fullDate = dateTimeString.split(",")[0];
                                }
                                el.updateDate = fullDate;
                                this.programListArr.push(this.setNameLang(el, 'prg'));
                            }
                            for (const prgs of this.programListArr) {
                                for (const el of formTotalAgreementArr) {
                                    if (parseInt(el.abp) === parseInt(prgs.abp) && parseInt(el.prg) === parseInt(prgs.prg)) {
                                        prgs.status = el.status;
                                        prgs.agrNameRu = el.nameRu
                                        prgs.agrNameKk = el.nameKk;
                                        prgs.agrNameEn = el.nameEn;
                                    }
                                }
                            }
                            await this.getBipStatus(this.programListArr);
                        }
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
                    }
                } else {
                    this.loading = false;
                    this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
                }
            } catch (error) {
                this.loading = false;
                console.log('error: ' + JSON.stringify(error));
                this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
            }
        },
        async getBipStatus(programListArr) {
            if (programListArr.length > 0) {
                const variant = this.curVersion.variant_uuid;
                const gu = this.curGu.code;
                const abp = this.curAbp.abp;
                const prgArr = [];
                for (const program of programListArr) {
                    if (program.developeType === 1) {
                        // console.log('variant: ' + variant + ' gu: ' + gu + ' prg: ' + program.prg + ' abp: ' + abp);
                        try {
                            const url = '/api-py/get_status_bip/' + variant + '/' + gu + '/' + program.prg + '/' + abp;
                            const result = await fetch(url)
                                .then(response => response.json());
                            if (result) {
                                const prgItems = {
                                    'abp': abp,
                                    'prg': program.prg,
                                    'status': '',
                                    items: []
                                }
                                for (const res of result) {
                                    const bipObj = {
                                        'prg': res.code_prg,
                                        'bipCode': res.bip_code,
                                        'codeStatus': res.code_status,
                                        'editable': res.editable
                                    }
                                    prgItems.items.push(bipObj);
                                }
                                prgArr.push(prgItems);
                            }
                        } catch (error) {
                        }
                    }
                }
                const editStatusList = getBipEditStatus(prgArr);
                programListArr = includeEditStatusToPrgList(editStatusList, programListArr);
            }

            function getBipEditStatus(prgArr) {
                if (prgArr.length) {
                    for (const prgItem of prgArr) {
                        prgItem.status = prgItem.items.length > 0 ? prgItem.items.some(obj => {
                            if (obj.editable === false) return false; else return true
                        }) : null;
                    }
                }
                return prgArr;
            }

            function includeEditStatusToPrgList(editStatusList, programListArr) {
                if (editStatusList.length > 0 && programListArr.length > 0) {
                    for (const prgEl of programListArr) {
                        for (const status of editStatusList) {
                            if (parseInt(prgEl.abp) === parseInt(status.abp) && parseInt(prgEl.prg) === parseInt(status.prg)) {
                                prgEl.editable = status.status;
                            }
                        }
                    }
                }
                return programListArr;
            }

        },

        padLeadingZeros(num, size) {    // -------добавление нулей перед значением в зависимости от размера значения
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        editBtn(item, editStatus) {
            if (item) {
                this.openAddTab = true;
                this.moduleTabSwitch = false;
                this.prgItems = item;
                this.editable = editStatus;
            }
        },
        moduleTabSwitches(item) {
            if (item) {
                this.openAddTab = !item;
                this.moduleTabSwitch = item;
            }
        },

        async deleteClk(item) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите удалить данные?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        const curYear = this.curYear.year;
                        const year = this.yearInPeriod;
                        const curRegion = this.curRegion;
                        const gu = this.curGu.code;
                        const dataType = this.curDataType.code;
                        const variant = this.curVersion.variant_uuid;
                        const params = {
                            'id': parseInt(item.id),
                            'gr': parseInt(item.gr),
                            'pgr': parseInt(item.pgr),
                            'abp': parseInt(item.abp),
                            'prg': parseInt(item.prg),
                            'status': parseInt(item.status),
                            'curYear': parseInt(curYear),
                            'year': parseInt(year),
                            'curRegion': curRegion,
                            'gu': gu,
                            'dataType': dataType,
                            'variant': variant
                        };
                        try {
                            const url = '/api/explanatory-note/budget-request-form-total/remove';
                            const response = await fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(params) // массив будет направлен на kotlin
                            });
                            const result = await response.json();
                            if ((response.status === 200) && (result === true)) {
                                this.makeToast('Очистка заявки', 'Данные успешно удалены');
                            }
                        } catch {
                            this.makeToast('Очистка заявки', 'Ошибка удаления заявки');
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                    this.loading = false;
                });
        },

        setNameLang(obj, codeName) {
            if (obj === null) {
                return null;
            }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>

<style lang="scss">

.enf-modal {
    .modal-title {
        font-size: 14px;
        font-weight: 700;
        color: #1C2A3E;
        border-bottom: none;
    }


    legend {
        margin-bottom: 10px;
    }

    .form-control {
        min-height: 360px;
        border: 1px solid #BCD2E0;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.filter-area {
}

.explanatory-note {
    .enf-form-group {
        position: relative;

        .text-area-block {
            min-height: 40px;
            border: 1px solid #BCD2E0;
            border-radius: 5px;
            background-color: #fff;
            padding: 5px 40px 5px 10px;
            line-height: 1.4;
            font-size: 14px;
            font-weight: 500;

            .enf-placeholder {
                color: #6087A0;
            }
        }

        .text-area-block-empty {
            min-height: 40px;
            border: 1px solid #EE0505;
            border-radius: 5px;
            background-color: #fff;
            padding: 5px 40px 5px 10px;
            line-height: 1.4;
            font-size: 14px;
            font-weight: 500;

            .enf-placeholder {
                color: #6087A0;
            }
        }

        .icon-pencil-edit,
        .icon-eye-open {
            position: absolute;
            right: 15px;
            color: #2196F3;
            cursor: pointer;
        }

        .icon-eye-open {
            top: 9px;
        }

        .icon-pencil-edit {
            top: 12px;
        }
    }

    .enf-top-info {
        position: absolute;
        top: 40px;
        right: 10px;
        z-index: 1005;

        .duplicate-radio {
            margin-top: 6px;
            display: flex;
            align-content: center;

            .custom-radio {
                text-transform: uppercase;
            }

            .enf-label {
                color: #1C2A3E;
                font-size: 14px;
                margin-top: 6px;
                font-weight: 700;
                margin-right: 20px;
            }
        }
    }

    .add-program {
        padding: 15px 20px;
        display: inline-flex;
        font-size: 14px;
        align-content: center;
        color: #2196F3 !important;
        cursor: pointer;

        i {
            margin-right: 15px;
            font-size: 20px;
        }

        b {
            text-decoration: underline;
        }
    }

    .td-link:hover {
        color: #2196F3 !important;
        cursor: pointer;
    }

    .more {
        .text-danger {
            button {
                color: #dc3545 !important;
            }
        }
    }

    .explanatory-note-form {
        .enf-title {
            padding: 10px 20px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-content: center;
            justify-content: flex-start;

            i {
                color: #2196F3;
                font-size: 17px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .enf-row {
            margin: 0;
            padding: 10px;
            border-top: 1px solid #D3DBE3;
            border-bottom: 1px solid #D3DBE3;
        }

        .form-group {
            margin: 0;
        }

        .form-row {
            margin: 0;
            align-items: flex-start;
        }
    }

    .table-container {
        .add-indicator {
            .icon-plus-circle {
                font-size: 20px;
                color: #2196F3;
            }

            span {
                color: #2196F3;
                display: inline-flex;
                align-content: center;
                cursor: pointer;

                b {
                    text-decoration: underline;
                }

                i {
                    margin-right: 10px;
                }
            }
        }

        .enf-row {
            margin: 0;
            padding: 0;
            border-top: none;
            border-bottom: none;
        }

    }


}
</style>